<template lang="">
    <div class="container">
        <div class="mb-3">
            <label class="form-label">إسم التصنيف الفرعي</label>
            <input class="form-control" type="text" v-model="name" />
        </div>
        <div class="mb-3">
            <label class="form-label">صورة التصنيف الفرعي</label>
            <input type="file" accept="image/*" @change="uploadImage($event)" id="file-input">
        </div>
        <div class="mb-3">
            <div class="form-group">
    <label for="exampleFormControlSelect1"> إلحاق بالتصنيف الأساسي</label>
    <select class="form-control" id="exampleFormControlSelect1" v-model="category_id">
      <option  v-for="item in gender" :key="item.id" :value="item.id">{{item.name}}</option>
    </select>
  </div>

  
        </div>
        <div class="mb-3">
            <button class="btn btn-dark" type="button" @click="saveCategory">حفظ التصنيف الفرعي</button>
        </div>
    </div>
</template>
<script>
import axios from 'axios';

export default {
    name:'add-sub-category',
    data(){
        return {
            gender:[],
            name:'',
            image:'',
            category_id:'',
        }
    },
    created(){
      this.getCategory();
  },

    methods: {
       async saveCategory(){
        try {
            let formData = new FormData();
            formData.append('name', this.name);
            formData.append('category_id', this.category_id);
            formData.append('image', this.image);

            await axios.post('https://boutiquette-store.ly/add-sub-category', formData);

            this.name = ''
            this.image = ''
            this.category_id = ''
            this.$router.push('/home');
            
        } catch (error) {
            null
        }
       },

       async getCategory(){
          try {
              const response = await axios.get('https://boutiquette-store.ly/categories-sub')
              this.gender = response.data.data
          
            
       
          } catch (error) {
            null
          }
      },

      uploadImage(event) {
        this.image = event.target.files[0];
    },

      
    }

}
</script>
<style lang="">
    
</style>